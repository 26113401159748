import React, { useState, useEffect } from 'react';
import { 
  Clock, Play, Pause, User, Users, Calendar, 
  FileText, Brain, UserPlus, ChevronRight,
  History, Users2, Home, AlertCircle,
  Mic, Smartphone, Copy, RefreshCw, 
  CheckCircle2, Loader2, CheckCircle, 
  Plus, MessageSquare, Eye, Flag,
  EyeOff, Ban, MoreVertical, Lock,
  Upload, Maximize2, ChevronDown,
  Heart, Globe, Activity, Clipboard, CheckSquare,
  Download, FolderOpen
} from 'lucide-react';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Avatar, AvatarFallback } from "../components/ui/avatar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { DialogFooter } from "../components/ui/dialog-footer";
import { Textarea } from "../components/ui/textarea";
import { Checkbox } from "../components/ui/checkbox";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from "../components/ui/select";

const observationCategories = {
  physicalPresentation: {
    title: "Physical Presentation & Motor Behavior",
    icon: <User className="h-4 w-4" />,
    items: [
      { id: 'p1', text: 'Overall appearance and grooming' },
      { id: 'p2', text: 'Posture and body positioning during session' },
      { id: 'p3', text: 'Level of physical activity (restlessness, fidgeting, ability to remain seated)' },
      { id: 'p4', text: 'Fine motor skills observed during activities' },
      { id: 'p5', text: 'Gross motor coordination' },
      { id: 'p6', text: 'Physical boundaries with examiner and environment' }
    ]
  },
  attention: {
    title: "Attention & Executive Function",
    icon: <Brain className="h-4 w-4" />,
    items: [
      { id: 'a1', text: 'Ability to maintain focus during structured tasks' },
      { id: 'a2', text: 'Response to environmental distractions' },
      { id: 'a3', text: 'Task initiation and completion patterns' },
      { id: 'a4', text: 'Organization of thoughts and materials' },
      { id: 'a5', text: 'Time management during assessment activities' },
      { id: 'a6', text: 'Working memory performance during tasks' },
      { id: 'a7', text: 'Flexibility in transitioning between activities' }
    ]
  },
  speech: {
    title: "Speech & Language",
    icon: <MessageSquare className="h-4 w-4" />,
    items: [
      { id: 's1', text: 'Rate and rhythm of speech' },
      { id: 's2', text: 'Volume and tone modulation' },
      { id: 's3', text: 'Language comprehension' },
      { id: 's4', text: 'Vocabulary and expression' },
      { id: 's5', text: 'Topic maintenance in conversation' },
      { id: 's6', text: 'Turn-taking in dialogue' },
      { id: 's7', text: 'Response latency to questions' }
    ]
  },
  emotional: {
    title: "Emotional Regulation & Affect",
    icon: <Heart className="h-4 w-4" />,
    items: [
      { id: 'e1', text: 'Overall emotional state' },
      { id: 'e2', text: 'Range of affect displayed' },
      { id: 'e3', text: 'Emotional reactivity to challenges' },
      { id: 'e4', text: 'Frustration tolerance' },
      { id: 'e5', text: 'Anxiety manifestations' },
      { id: 'e6', text: 'Self-awareness of emotions' },
      { id: 'e7', text: 'Response to praise and correction' }
    ]
  },
  social: {
    title: "Social Interaction",
    icon: <Users className="h-4 w-4" />,
    items: [
      { id: 'si1', text: 'Eye contact quality and frequency' },
      { id: 'si2', text: 'Rapport building with examiner' },
      { id: 'si3', text: 'Social reciprocity' },
      { id: 'si4', text: 'Understanding of social cues' },
      { id: 'si5', text: 'Personal space awareness' },
      { id: 'si6', text: 'Help-seeking behaviors' },
      { id: 'si7', text: 'Response to authority' }
    ]
  },
  cognitive: {
    title: "Cognitive Processing",
    icon: <Brain className="h-4 w-4" />,
    items: [
      { id: 'c1', text: 'Processing speed' },
      { id: 'c2', text: 'Response patterns to complex instructions' },
      { id: 'c3', text: 'Problem-solving approach' },
      { id: 'c4', text: 'Abstract thinking capabilities' },
      { id: 'c5', text: 'Memory recall during interactions' },
      { id: 'c6', text: 'Learning from feedback' },
      { id: 'c7', text: 'Decision-making patterns' }
    ]
  },
  behavioral: {
    title: "Behavioral Regulation",
    icon: <Activity className="h-4 w-4" />,
    items: [
      { id: 'b1', text: 'Impulse control' },
      { id: 'b2', text: 'Response inhibition' },
      { id: 'b3', text: 'Rule compliance' },
      { id: 'b4', text: 'Self-monitoring abilities' },
      { id: 'b5', text: 'Persistence with difficult tasks' },
      { id: 'b6', text: 'Coping strategies employed' },
      { id: 'b7', text: 'Self-correction patterns' }
    ]
  },
  environmental: {
    title: "Environmental Interaction",
    icon: <Globe className="h-4 w-4" />,
    items: [
      { id: 'en1', text: 'Adaptation to assessment setting' },
      { id: 'en2', text: 'Response to structure' },
      { id: 'en3', text: 'Exploration of environment' },
      { id: 'en4', text: 'Use of materials and resources' },
      { id: 'en5', text: 'Environmental awareness' },
      { id: 'en6', text: 'Sensory responses' },
      { id: 'en7', text: 'Safety awareness' }
    ]
  },
  clinical: {
    title: "Additional Clinical Observations",
    icon: <Clipboard className="h-4 w-4" />,
    items: [
      { id: 'ac1', text: 'Unusual or concerning behaviors' },
      { id: 'ac2', text: 'Strengths demonstrated' },
      { id: 'ac3', text: 'Parent-child interaction (if observed)' },
      { id: 'ac4', text: 'Response to breaks/downtime' },
      { id: 'ac5', text: 'Level of effort and motivation' },
      { id: 'ac6', text: 'Notable patterns or themes' },
      { id: 'ac7', text: 'Areas requiring follow-up' }
    ]
  },
  validity: {
    title: "Validity Indicators",
    icon: <CheckSquare className="h-4 w-4" />,
    items: [
      { id: 'v1', text: 'Consistency of presentation' },
      { id: 'v2', text: 'Effort level' },
      { id: 'v3', text: 'Response style' },
      { id: 'v4', text: 'Symptom presentation' },
      { id: 'v5', text: 'Parent report consistency' },
      { id: 'v6', text: 'School report consistency' },
      { id: 'v7', text: 'Overall assessment validity' }
    ]
  }
};

const GlobalObservationsModal = ({ isOpen, onClose, observations, onUpdate }) => {
  const [globalNotes, setGlobalNotes] = useState('');
  const [activeNote, setActiveNote] = useState(null);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[90vw] h-[90vh]">
        <DialogHeader>
          <DialogTitle>Global Observations</DialogTitle>
        </DialogHeader>
        <div className="flex gap-6 h-full overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(observations).map(([key, category]) => (
                <Card key={key}>
                  <CardHeader className="py-2">
                    <CardTitle className="text-sm flex items-center">
                      {category.icon}
                      <span className="ml-2">{category.title}</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="py-2">
                    {category.items.map(item => (
                      <div key={item.id} className="mb-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Checkbox 
                              checked={item.checked}
                              onCheckedChange={(checked) => {
                                onUpdate(key, item.id, { checked });
                                if (checked) {
                                  setGlobalNotes(prev => 
                                    prev + `• ${category.title}: ${item.text}\n`
                                  );
                                }
                              }}
                            />
                            <span className="text-sm">{item.text}</span>
                          </div>
                          <Button 
                            variant="ghost" 
                            size="sm"
                            onClick={() => setActiveNote(activeNote === item.id ? null : item.id)}
                          >
                            <MessageSquare className="h-3 w-3" />
                          </Button>
                        </div>
                        {activeNote === item.id && (
                          <div className="mt-1 pl-6">
                            <Textarea 
                              placeholder="Add specific notes..."
                              value={item.notes || ''}
                              onChange={(e) => 
                                onUpdate(key, item.id, { notes: e.target.value })
                              }
                              className="text-sm min-h-[60px]"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>

          <div className="w-80">
            <Card>
              <CardHeader className="py-2">
                <CardTitle className="text-sm">Global Notes</CardTitle>
              </CardHeader>
              <CardContent>
                <Textarea 
                  className="min-h-[calc(100vh-300px)]"
                  value={globalNotes}
                  onChange={(e) => setGlobalNotes(e.target.value)}
                  placeholder="Checked items will appear here..."
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const GlobalObservations = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Global Observations</CardTitle>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" onClick={handleWNL}>
            WNL
          </Button>
          <Button 
            variant="ghost" 
            size="sm"
            onClick={() => setShowFullScreen(true)}
          >
            <Maximize2 className="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {Object.entries(observationCategories).map(([key, category]) => (
            <div key={key} className="border rounded-lg">
              <button
                className="w-full p-3 flex items-center justify-between hover:bg-gray-50"
                onClick={() => setExpandedCategory(
                  expandedCategory === key ? null : key
                )}
              >
                <div className="flex items-center gap-2">
                  {category.icon}
                  <span className="font-medium text-sm">{category.title}</span>
                </div>
                {expandedCategory === key ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronRight className="h-4 w-4" />
                )}
              </button>
              {expandedCategory === key && (
                <div className="p-3 border-t bg-gray-50">
                  {/* Collapsed view of items */}
                  <div className="space-y-2">
                    {category.items.map(item => (
                      <div key={item.id} className="flex items-center gap-2">
                        <Checkbox checked={item.checked} />
                        <span className="text-sm">{item.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>

      <GlobalObservationsModal 
        isOpen={showFullScreen}
        onClose={() => setShowFullScreen(false)}
        observations={observationCategories}
        onUpdate={(categoryKey, itemId, updates) => {
          // Handle updates to observations
          console.log('Update:', categoryKey, itemId, updates);
          // You can implement the actual update logic here
        }}
      />
    </Card>
  );
};

const RecordingTile = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [deviceCode, setDeviceCode] = useState(null);
  const [showDeviceInput, setShowDeviceInput] = useState(false);
  const [transcript, setTranscript] = useState([]);
  
  // Generate a random 4-digit code
  const generateCode = () => {
    const code = Math.floor(1000 + Math.random() * 9000);
    setDeviceCode(code);
  };

  // Mock transcription updates
  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTranscript(prev => [...prev, {
          speaker: Math.random() > 0.5 ? 'Dr. Barnes' : 'Patient',
          text: 'This is a sample transcription text...',
          timestamp: new Date().toLocaleTimeString()
        }]);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  return (
    <Card className="mb-6">
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle className="flex items-center">
            <Mic className="h-4 w-4 mr-2" />
            Session Recording
          </CardTitle>
          {isRecording && (
            <div className="flex items-center text-red-500">
              <span className="animate-pulse mr-2">●</span>
              Recording
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {/* Recording Controls */}
          <div className="flex space-x-2">
            <Button 
              variant={isRecording ? "destructive" : "default"}
              onClick={() => setIsRecording(!isRecording)}
              className="flex-1"
            >
              {isRecording ? (
                <><Pause className="mr-2 h-4 w-4" /> Stop Recording</>
              ) : (
                <><Mic className="mr-2 h-4 w-4" /> Start Recording</>
              )}
            </Button>
            <Button 
              variant="outline" 
              onClick={() => setShowDeviceInput(!showDeviceInput)}
            >
              <Smartphone className="h-4 w-4" />
            </Button>
          </div>

          {/* Device Pairing Section */}
          {showDeviceInput && (
            <div className="bg-gray-50 p-4 rounded-lg space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium">Device Code</span>
                <Button 
                  variant="ghost" 
                  size="sm"
                  onClick={generateCode}
                >
                  <RefreshCw className="h-4 w-4 mr-1" />
                  Generate New
                </Button>
              </div>
              {deviceCode ? (
                <div className="flex items-center justify-between bg-white p-3 rounded border">
                  <span className="text-2xl font-mono font-bold tracking-wider">
                    {deviceCode}
                  </span>
                  <Button 
                    variant="ghost" 
                    size="sm"
                    onClick={() => navigator.clipboard.writeText(deviceCode)}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                <Button 
                  className="w-full" 
                  onClick={generateCode}
                >
                  Generate Code
                </Button>
              )}
              <p className="text-xs text-gray-500">
                Enter this code in the PsychAccess mobile app to start recording from your device
              </p>
            </div>
          )}

          {/* Live Transcription */}
          {isRecording && (
            <div className="mt-4">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium">Live Transcription</span>
                <div className="flex items-center text-xs text-gray-500">
                  <Loader2 className="h-3 w-3 animate-spin mr-1" />
                  Transcribing...
                </div>
              </div>
              <div className="bg-gray-50 rounded-lg p-4 h-[200px] overflow-y-auto space-y-2">
                {transcript.map((entry, index) => (
                  <div key={index} className="flex space-x-2">
                    <span className="text-xs text-gray-500">{entry.timestamp}</span>
                    <span className="text-xs font-medium">{entry.speaker}:</span>
                    <span className="text-sm flex-1">{entry.text}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const DocumentReview = () => {
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [reviewNotes, setReviewNotes] = useState({});
  const [showAddDocs, setShowAddDocs] = useState(false);

  const documents = [
    { 
      id: 1, 
      name: 'Previous School Records', 
      type: 'PDF', 
      status: 'Reviewed',
      dateReceived: '2024-03-15',
      source: 'Lincoln Elementary',
      content: {
        grades: { math: 'B', english: 'C', science: 'B-' },
        attendance: '95%',
        teacherComments: 'Struggles with sustained attention...'
      },
      aiSummary: 'Academic performance shows inconsistency. Strong mathematical abilities but challenges in reading comprehension. Multiple teacher notes regarding attention span.',
      signals: [
        { type: 'attention', severity: 'moderate', description: 'Consistent mentions of focus issues' },
        { type: 'academic', severity: 'mild', description: 'Uneven performance across subjects' }
      ]
    },
    { 
      id: 2, 
      name: 'Medical History', 
      type: 'PDF', 
      status: 'Pending Review',
      dateReceived: '2024-03-16',
      source: 'Dr. Johnson Pediatrics',
      content: {
        medications: ['None current', 'Previous: Melatonin'],
        conditions: ['Seasonal allergies'],
        development: 'Normal milestones'
      },
      aiSummary: 'No significant medical concerns. Sleep issues noted in previous visits.',
      signals: [
        { type: 'sleep', severity: 'mild', description: 'History of sleep difficulties' }
      ]
    },
    // ... other documents
  ];

  const DocumentModal = ({ doc, onClose }) => {
    const [activeTab, setActiveTab] = useState('content');
    const [isComplete, setIsComplete] = useState(doc.status === 'Reviewed');

    return (
      <Dialog open={!!doc} onOpenChange={onClose}>
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-hidden flex flex-col">
          <DialogHeader>
            <div className="flex items-center justify-between">
              <DialogTitle>{doc.name}</DialogTitle>
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">
                  Received: {doc.dateReceived}
                </span>
                <span className="text-sm text-gray-500">
                  Source: {doc.source}
                </span>
              </div>
            </div>
          </DialogHeader>

          <div className="flex-1 overflow-hidden">
            <div className="flex h-full">
              {/* Left side - Document content and review */}
              <div className="flex-1 border-r p-4 overflow-y-auto">
                <div className="flex space-x-4 mb-4">
                  <Button
                    variant={activeTab === 'content' ? 'default' : 'outline'}
                    onClick={() => setActiveTab('content')}
                  >
                    <FileText className="h-4 w-4 mr-2" />
                    Content
                  </Button>
                  <Button
                    variant={activeTab === 'notes' ? 'default' : 'outline'}
                    onClick={() => setActiveTab('notes')}
                  >
                    <MessageSquare className="h-4 w-4 mr-2" />
                    Review Notes
                  </Button>
                </div>

                {activeTab === 'content' ? (
                  <div className="prose max-w-none">
                    {/* Render document content based on type */}
                    <pre className="text-sm whitespace-pre-wrap">
                      {JSON.stringify(doc.content, null, 2)}
                    </pre>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <Textarea
                      placeholder="Add your review notes here..."
                      value={reviewNotes[doc.id] || ''}
                      onChange={(e) => setReviewNotes(prev => ({
                        ...prev,
                        [doc.id]: e.target.value
                      }))}
                      className="min-h-[200px]"
                    />
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        checked={isComplete}
                        onCheckedChange={setIsComplete}
                        id="markComplete"
                      />
                      <label htmlFor="markComplete">
                        Mark document as reviewed
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* Right side - AI Summary and Signals */}
              <div className="w-80 p-4 bg-gray-50 overflow-y-auto">
                <div className="space-y-6">
                  {/* AI Summary */}
                  <div>
                    <h3 className="text-sm font-medium flex items-center mb-2">
                      <Brain className="h-4 w-4 mr-2 text-purple-500" />
                      AI Summary
                    </h3>
                    <p className="text-sm text-gray-600">
                      {doc.aiSummary}
                    </p>
                  </div>

                  {/* Signals */}
                  {doc.signals && doc.signals.length > 0 && (
                    <div>
                      <h3 className="text-sm font-medium flex items-center mb-2">
                        <Flag className="h-4 w-4 mr-2 text-red-500" />
                        Detected Signals
                      </h3>
                      <div className="space-y-2">
                        {doc.signals.map((signal, index) => (
                          <div 
                            key={index}
                            className="bg-white p-3 rounded border"
                          >
                            <div className="flex items-center justify-between mb-1">
                              <span className="font-medium text-sm">
                                {signal.type}
                              </span>
                              <span className={`text-xs px-2 py-1 rounded-full ${
                                signal.severity === 'high' ? 'bg-red-100 text-red-800' :
                                signal.severity === 'moderate' ? 'bg-yellow-100 text-yellow-800' :
                                'bg-blue-100 text-blue-800'
                              }`}>
                                {signal.severity}
                              </span>
                            </div>
                            <p className="text-sm text-gray-600">
                              {signal.description}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Card className="mb-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Document Review</CardTitle>
        <Button variant="outline" size="sm" onClick={() => setShowAddDocs(true)}>
          <Plus className="h-4 w-4 mr-2" />
          Add Documents
        </Button>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {documents.map(doc => (
            <div 
              key={doc.id} 
              className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg border cursor-pointer"
              onClick={() => setSelectedDoc(doc)}
            >
              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <FileText className="h-4 w-4 mr-2 text-blue-500" />
                  <div>
                    <div className="font-medium">{doc.name}</div>
                    <div className="text-sm text-gray-500">{doc.type}</div>
                  </div>
                </div>
                {doc.signals && doc.signals.length > 0 && (
                  <span className="flex items-center text-red-500">
                    <AlertCircle className="h-4 w-4" />
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <span className={`text-sm px-2 py-1 rounded-full ${
                  doc.status === 'Reviewed' ? 'bg-green-100 text-green-800' :
                  doc.status === 'Pending Review' ? 'bg-yellow-100 text-yellow-800' :
                  doc.status === 'New' ? 'bg-blue-100 text-blue-800' :
                  'bg-gray-100 text-gray-800'
                }`}>
                  {doc.status}
                </span>
                <Button variant="ghost" size="sm">
                  <Eye className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </CardContent>

      {selectedDoc && (
        <DocumentModal 
          doc={selectedDoc} 
          onClose={() => setSelectedDoc(null)} 
        />
      )}
    </Card>
  );
};

const SignalsPanel = () => {
  const [signals, setSignals] = useState([
    { 
      id: 1, 
      type: 'Attention',
      description: 'Difficulty maintaining focus during conversation',
      confidence: 0.85,
      sources: ['Interview', 'Behavioral Observation'],
      status: 'active' // can be 'active', 'rejected', or 'actioned'
    },
    { 
      id: 2, 
      type: 'Academic',
      description: 'Inconsistent performance across subjects',
      confidence: 0.92,
      sources: ['School Records', 'Parent Report'],
      status: 'active'
    },
    { 
      id: 3, 
      type: 'Social',
      description: 'Strong peer relationships but conflict with authority',
      confidence: 0.78,
      sources: ['Teacher Report', 'Parent Interview'],
      status: 'active'
    }
  ]);

  const [showRejected, setShowRejected] = useState(false);
  const [selectedSignal, setSelectedSignal] = useState(null);

  const handleSignalAction = (signalId, action) => {
    setSignals(signals.map(signal => 
      signal.id === signalId 
        ? { ...signal, status: action } 
        : signal
    ));
    setSelectedSignal(null);
  };

  const SignalActionMenu = ({ signal }) => (
    <Dialog open={!!selectedSignal} onOpenChange={() => setSelectedSignal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Signal Actions</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <h3 className="text-sm font-medium">Recommended Measures</h3>
            <div className="space-y-2">
              <Button variant="outline" className="w-full justify-start text-left">
                <FileText className="h-4 w-4 mr-2" />
                ADHD Rating Scale
              </Button>
              <Button variant="outline" className="w-full justify-start text-left">
                <FileText className="h-4 w-4 mr-2" />
                Behavior Assessment System
              </Button>
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-sm font-medium">Double Down Actions</h3>
            <div className="space-y-2">
              <Button 
                variant="outline" 
                className="w-full justify-start text-left"
                onClick={() => handleSignalAction(signal.id, 'actioned')}
              >
                <Brain className="h-4 w-4 mr-2" />
                Schedule Focused Assessment
              </Button>
              <Button 
                variant="outline" 
                className="w-full justify-start text-left"
                onClick={() => handleSignalAction(signal.id, 'actioned')}
              >
                <Users className="h-4 w-4 mr-2" />
                Request Teacher Feedback
              </Button>
            </div>
          </div>

          <div className="border-t pt-4">
            <Button 
              variant="destructive" 
              className="w-full"
              onClick={() => handleSignalAction(signal.id, 'rejected')}
            >
              Decline Signal
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );

  const filteredSignals = showRejected 
    ? signals 
    : signals.filter(s => s.status !== 'rejected');

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle>AI Detected Signals</CardTitle>
          <Button 
            variant="ghost" 
            size="sm"
            onClick={() => setShowRejected(!showRejected)}
          >
            {showRejected ? (
              <Eye className="h-4 w-4" />
            ) : (
              <EyeOff className="h-4 w-4" />
            )}
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {filteredSignals.map(signal => (
            <div 
              key={signal.id} 
              className={`p-3 rounded-lg ${
                signal.status === 'rejected' 
                  ? 'bg-red-50 border border-red-100' 
                  : signal.status === 'actioned'
                  ? 'bg-green-50 border border-green-100'
                  : 'bg-gray-50'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <span className="font-medium text-blue-600">{signal.type}</span>
                  {signal.status === 'rejected' && (
                    <div className="flex items-center text-red-500">
                      <Ban className="h-4 w-4" />
                    </div>
                  )}
                  {signal.status === 'actioned' && (
                    <div className="flex items-center text-green-500">
                      <CheckCircle className="h-4 w-4" />
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-sm bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                    {(signal.confidence * 100).toFixed(0)}% confidence
                  </span>
                  {signal.status !== 'rejected' && (
                    <Button 
                      variant="ghost" 
                      size="sm"
                      onClick={() => setSelectedSignal(signal)}
                    >
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              </div>
              <p className="text-sm text-gray-700 mb-2">{signal.description}</p>
              <div className="flex flex-wrap gap-1">
                {signal.sources.map((source, index) => (
                  <span 
                    key={index} 
                    className={`text-xs px-2 py-1 rounded ${
                      signal.status === 'rejected'
                        ? 'bg-red-100 text-red-700'
                        : signal.status === 'actioned'
                        ? 'bg-green-100 text-green-700'
                        : 'bg-gray-200 text-gray-700'
                    }`}
                  >
                    {source}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </CardContent>

      {selectedSignal && <SignalActionMenu signal={selectedSignal} />}
    </Card>
  );
};

const CloseSessionDialog = ({ isOpen, onClose }) => {
  const [confirmText, setConfirmText] = useState('');
  const [checklist, setChecklist] = useState({
    notesComplete: false,
    documentsReviewed: false,
    signalsAddressed: false,
    nextStepsPlanned: false
  });

  const allChecked = Object.values(checklist).every(Boolean);
  const canClose = allChecked && confirmText === 'close session';

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Close Session</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            {Object.entries(checklist).map(([key, value]) => (
              <div className="flex items-center space-x-2" key={key}>
                <Checkbox 
                  id={key}
                  checked={value}
                  onCheckedChange={(checked) => 
                    setChecklist(prev => ({ ...prev, [key]: checked }))
                  }
                />
                <label htmlFor={key} className="text-sm">
                  {key.split(/(?=[A-Z])/).join(' ')}
                </label>
              </div>
            ))}
          </div>

          <div className="space-y-2">
            <Label>Type 'close session' to confirm</Label>
            <Input
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value.toLowerCase())}
              placeholder="close session"
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button 
            disabled={!canClose}
            onClick={() => {
              // Handle session close
              onClose();
            }}
          >
            Close Session
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const PostSessionModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('legal');
  const [legalNote, setLegalNote] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');

  // Move noteTemplates inside the component
  const noteTemplates = [
    {
      id: 1,
      name: 'Standard Intake Note',
      template: `<name> (<age>) presented for an initial intake assessment accompanied by <parent>. The session lasted <duration> minutes. 

Chief Concerns:
<concerns>

Behavioral Observations:
<observations>

Clinical Interview:
During the clinical interview, <name> <interview_notes>

Mental Status:
<mental_status>

Assessment Tools Administered:
<assessments>

Summary and Recommendations:
Based on today's evaluation, <summary>

Plan:
1. <plan_1>
2. <plan_2>
3. <plan_3>

Next Session: <next_session>
Duration: <duration> minutes
Provider: <provider_name>, <credentials>`
    },
    // Add more templates as needed
  ];

  const handleTemplateSelect = (templateId) => {
    const template = noteTemplates.find(t => t.id === templateId);
    if (template) {
      setLegalNote(template.template);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Post Session Tasks</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <Tabs defaultValue={activeTab} className="w-full" onValueChange={setActiveTab}>
              <TabsList className="grid w-full grid-cols-1">
                <TabsTrigger value="legal">Legal Note</TabsTrigger>
              </TabsList>

              <TabsContent value="legal" className="space-y-4 mt-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-sm font-medium">Legal Note Generation</h3>
                  <div className="flex space-x-2">
                    <Select onValueChange={handleTemplateSelect}>
                      <SelectTrigger className="w-[200px] bg-white">
                        <SelectValue placeholder="Select Template" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        {noteTemplates.map(template => (
                          <SelectItem key={template.id} value={template.id}>
                            {template.name}
                          </SelectItem>
                        ))}
                        <SelectItem value="new">Create New Template</SelectItem>
                      </SelectContent>
                    </Select>
                    <Button variant="outline" size="sm">
                      <Brain className="h-4 w-4 mr-2" />
                      AI Enhance
                    </Button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-4">
                    <div className="relative">
                      <Textarea 
                        value={legalNote}
                        onChange={(e) => setLegalNote(e.target.value)}
                        className="min-h-[600px] font-mono"
                        placeholder="Generated note will appear here..."
                      />
                      <Button 
                        variant="ghost" 
                        size="sm" 
                        className="absolute top-2 right-2"
                        onClick={() => {/* Handle transcription */}}
                      >
                        <Mic className="h-4 w-4" />
                      </Button>
                    </div>
                    <div className="flex justify-between">
                      <Button variant="outline" size="sm">
                        <Download className="h-4 w-4 mr-2" />
                        Export as Word
                      </Button>
                      <div className="space-x-2">
                        <Button variant="outline" size="sm">
                          <Copy className="h-4 w-4 mr-2" />
                          Copy to Clipboard
                        </Button>
                        <Button 
                          variant="outline" 
                          size="sm"
                          onClick={() => setShowSaveTemplateDialog(true)}
                        >
                          <Plus className="h-4 w-4 mr-2" />
                          Add As New Template
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <Card>
                      <CardHeader className="py-2">
                        <CardTitle className="text-sm">Available Data Sources</CardTitle>
                      </CardHeader>
                      <CardContent className="space-y-2">
                        <div className="bg-gray-50 p-2 rounded">
                          <h4 className="text-sm font-medium mb-1">Session Notes</h4>
                          <p className="text-xs text-gray-600">Click to insert at cursor position</p>
                        </div>

                        <div className="bg-gray-50 p-2 rounded">
                          <h4 className="text-sm font-medium mb-1">Observations</h4>
                          <div className="space-y-1">
                            {/* Map through checked observations */}
                          </div>
                        </div>

                        <div className="bg-gray-50 p-2 rounded">
                          <h4 className="text-sm font-medium mb-1">Transcription Highlights</h4>
                        </div>

                        <div className="bg-gray-50 p-2 rounded">
                          <h4 className="text-sm font-medium mb-1">AI Signals</h4>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={onClose}>Close</Button>
            <Button>Save & Complete</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showSaveTemplateDialog} onOpenChange={setShowSaveTemplateDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Save as New Template</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Template Name</Label>
              <Input
                id="name"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                placeholder="Enter template name..."
              />
            </div>
            <div className="grid gap-2">
              <Label>Category</Label>
              <Input value="Intake" disabled />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowSaveTemplateDialog(false)}>
              Cancel
            </Button>
            <Button onClick={() => {
              // Handle saving the template
              const newTemplate = {
                id: noteTemplates.length + 1,
                name: newTemplateName,
                template: legalNote,
                category: 'Intake'
              };
              // Add to templates
              noteTemplates.push(newTemplate);
              setShowSaveTemplateDialog(false);
              setNewTemplateName('');
            }}>
              Save Template
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const SessionInterface = () => {
  const [sessionType, setSessionType] = useState('intake');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [sessionTime, setSessionTime] = useState(0);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [showPostSessionModal, setShowPostSessionModal] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [showFullScreenNotes, setShowFullScreenNotes] = useState(false);

  // Add observations state here
  const [observations, setObservations] = useState([
    { id: 1, category: 'Attention', items: [
      { id: 'a1', text: 'Difficulty focusing', checked: false, note: '' },
      { id: 'a2', text: 'Easily distracted', checked: false, note: '' },
      { id: 'a3', text: 'Hyperfocus present', checked: false, note: '' }
    ]},
    { id: 2, category: 'Behavior', items: [
      { id: 'b1', text: 'Fidgeting', checked: false, note: '' },
      { id: 'b2', text: 'Impulsive responses', checked: false, note: '' },
      { id: 'b3', text: 'Restlessness', checked: false, note: '' }
    ]},
    { id: 3, category: 'Social', items: [
      { id: 's1', text: 'Good eye contact', checked: false, note: '' },
      { id: 's2', text: 'Appropriate interaction', checked: false, note: '' },
      { id: 's3', text: 'Response to praise', checked: false, note: '' }
    ]}
  ]);

  // Add signals state
  const [signals, setSignals] = useState([
    { 
      id: 1, 
      type: 'Attention',
      description: 'Difficulty maintaining focus during conversation',
      confidence: 0.85,
      sources: ['Interview', 'Behavioral Observation'],
      status: 'active'
    },
    { 
      id: 2, 
      type: 'Academic',
      description: 'Inconsistent performance across subjects',
      confidence: 0.92,
      sources: ['School Records', 'Parent Report'],
      status: 'active'
    },
    { 
      id: 3, 
      type: 'Social',
      description: 'Strong peer relationships but conflict with authority',
      confidence: 0.78,
      sources: ['Teacher Report', 'Parent Interview'],
      status: 'active'
    }
  ]);

  // Add handler for signal actions
  const handleSignalAction = (signalId, action) => {
    setSignals(signals.map(signal => 
      signal.id === signalId 
        ? { ...signal, status: action } 
        : signal
    ));
  };

  // Add handler functions for observations
  const handleCheckObservation = (categoryId, itemId) => {
    setObservations(observations.map(category => {
      if (category.id === categoryId) {
        return {
          ...category,
          items: category.items.map(item => 
            item.id === itemId ? { ...item, checked: !item.checked } : item
          )
        };
      }
      return category;
    }));
  };

  const handleWNL = () => {
    setObservations(observations.map(category => ({
      ...category,
      items: category.items.map(item => ({
        ...item,
        checked: true,
        note: "Within Normal Limits"
      }))
    })));
  };

  // Mock data
  const patientInfo = {
    name: "John Smith",
    age: "13",
    id: "PT-2024-001",
    nextSession: "Assessment Session 1 - Mar 25, 2024",
    photo: "/avatars/john.jpg"
  };

  const participants = [
    { id: 1, name: "Dr. Barnes", role: "Clinician", isPresent: true },
    { id: 2, name: "Sarah Smith", role: "Mother", isPresent: true },
    { id: 3, name: "John Smith", role: "Patient", isPresent: true }
  ];

  // Add documents state
  const [documents, setDocuments] = useState([
    { 
      id: 1, 
      name: 'Previous School Records', 
      type: 'PDF', 
      status: 'Reviewed',
      dateReceived: '2024-03-15',
      source: 'Lincoln Elementary',
      content: {
        grades: { math: 'B', english: 'C', science: 'B-' },
        attendance: '95%',
        teacherComments: 'Struggles with sustained attention...'
      },
      aiSummary: 'Academic performance shows inconsistency. Strong mathematical abilities but challenges in reading comprehension. Multiple teacher notes regarding attention span.',
      signals: [
        { type: 'attention', severity: 'moderate', description: 'Consistent mentions of focus issues' },
        { type: 'academic', severity: 'mild', description: 'Uneven performance across subjects' }
      ]
    },
    { 
      id: 2, 
      name: 'Medical History', 
      type: 'PDF', 
      status: 'Pending Review',
      dateReceived: '2024-03-16',
      source: 'Dr. Johnson Pediatrics',
      content: {
        medications: ['None current', 'Previous: Melatonin'],
        conditions: ['Seasonal allergies'],
        development: 'Normal milestones'
      },
      aiSummary: 'No significant medical concerns. Sleep issues noted in previous visits.',
      signals: [
        { type: 'sleep', severity: 'mild', description: 'History of sleep difficulties' }
      ]
    },
    {
      id: 3,
      name: 'Parent Questionnaire',
      type: 'Form',
      status: 'New',
      dateReceived: '2024-03-17',
      source: 'Parent Portal',
      signals: []
    }
  ]);

  // Add document handlers if needed
  const handleDocumentUpdate = (docId, updates) => {
    setDocuments(documents.map(doc => 
      doc.id === docId ? { ...doc, ...updates } : doc
    ));
  };

  // Add these state variables at the top of the SessionInterface component
  const [showDeviceInput, setShowDeviceInput] = useState(false);
  const [deviceCode, setDeviceCode] = useState(null);
  const [transcript, setTranscript] = useState([]);

  // Add this function to generate device codes
  const generateCode = () => {
    const code = Math.floor(1000 + Math.random() * 9000);
    setDeviceCode(code);
  };

  const handleExtractHighlights = (docId) => {
    // Implement highlight extraction logic
    console.log('Extracting highlights from document:', docId);
  };

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      {/* Top Navigation */}
      <div className="bg-white border-b">
        <div className="container mx-auto">
          <Tabs defaultValue={sessionType} className="w-full">
            <TabsList className="grid w-full grid-cols-5">
              <TabsTrigger value="intake">Intake</TabsTrigger>
              <TabsTrigger value="assessment-async">Assessment (Async)</TabsTrigger>
              <TabsTrigger value="assessment-inperson">Assessment (In Person)</TabsTrigger>
              <TabsTrigger value="adhoc">Ad-Hoc</TabsTrigger>
              <TabsTrigger value="feedback">Feedback</TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="flex justify-end py-2 space-x-2">
            <Button 
              variant="outline"
              onClick={() => setShowPostSessionModal(true)}
            >
              <FileText className="h-4 w-4 mr-2" />
              Post Session Tasks
            </Button>
            <Button 
              variant="outline"
              className="border-red-200 text-red-600 hover:bg-red-50"
              onClick={() => setShowCloseDialog(true)}
            >
              <Lock className="h-4 w-4 mr-2" />
              Close Session
            </Button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 container mx-auto py-6">
        {/* Main Content Grid */}
        <div className="grid grid-cols-12 gap-6 h-full">
          {/* Left Sidebar - Patient Info & Controls */}
          <div className="col-span-2 space-y-4">
            {/* Compact Patient Info */}
            <Card>
              <CardHeader className="p-4">
                <div className="flex items-center space-x-3">
                  <Avatar className="h-10 w-10">
                    <AvatarFallback>{patientInfo.name[0]}</AvatarFallback>
                  </Avatar>
                  <div>
                    <CardTitle className="text-base">{patientInfo.name}</CardTitle>
                    <p className="text-xs text-gray-500">ID: {patientInfo.id}</p>
                  </div>
                </div>
              </CardHeader>
            </Card>

            {/* Quick Actions */}
            <div className="space-y-2">
              <Button variant="outline" size="sm" className="w-full justify-start">
                <FileText className="h-4 w-4 mr-2" />
                Background
              </Button>
              <Button variant="outline" size="sm" className="w-full justify-start">
                <History className="h-4 w-4 mr-2" />
                Past Tests
              </Button>
              <Button variant="outline" size="sm" className="w-full justify-start">
                <Users className="h-4 w-4 mr-2" />
                Family
              </Button>
            </div>

            {/* Session Timer */}
            <Card>
              <CardContent className="p-4">
                <div className="text-2xl font-bold text-center">00:45:00</div>
                <p className="text-xs text-gray-500 text-center">Remaining</p>
                <Button 
                  className="w-full mt-2"
                  variant={isSessionActive ? "destructive" : "default"}
                >
                  {isSessionActive ? "End" : "Start"}
                </Button>
              </CardContent>
            </Card>

            {/* Participants */}
            <Card>
              <CardContent className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm font-medium">Participants</span>
                  <Button variant="ghost" size="sm">
                    <UserPlus className="h-4 w-4" />
                  </Button>
                </div>
                <div className="space-y-2">
                  {participants.map((p) => (
                    <div key={p.id} className="flex items-center text-sm">
                      <div className={`w-2 h-2 rounded-full ${p.isPresent ? 'bg-green-500' : 'bg-gray-300'} mr-2`} />
                      <span>{p.name}</span>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Main Content Area */}
          <div className="col-span-7 space-y-4">
            {/* Session Header */}
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-lg font-semibold">Intake Session</h2>
                <p className="text-sm text-gray-500">Initial Assessment</p>
              </div>
              <div className="flex space-x-2">
                <Button variant="outline" size="sm">
                  <Brain className="h-4 w-4 mr-2" />
                  AI Assist
                </Button>
              </div>
            </div>

            {/* Main Content Grid */}
            <div className="grid grid-cols-2 gap-4">
              {/* Observations */}
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-sm font-medium">Global Observations</h3>
                  <div className="flex space-x-2">
                    <Button variant="outline" size="sm" onClick={handleWNL}>
                      WNL
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="sm"
                      onClick={() => setShowFullScreen(true)}
                    >
                      <Maximize2 className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                <div className="space-y-2">
                  {Object.entries(observationCategories).map(([key, category]) => (
                    <div key={key} className="border rounded-lg">
                      <button
                        className="w-full p-3 flex items-center justify-between hover:bg-gray-50"
                        onClick={() => setExpandedCategory(
                          expandedCategory === key ? null : key
                        )}
                      >
                        <div className="flex items-center gap-2">
                          {category.icon}
                          <span className="font-medium text-sm">{category.title}</span>
                        </div>
                        {expandedCategory === key ? (
                          <ChevronDown className="h-4 w-4" />
                        ) : (
                          <ChevronRight className="h-4 w-4" />
                        )}
                      </button>
                      {expandedCategory === key && (
                        <div className="p-3 border-t bg-gray-50">
                          <div className="space-y-2">
                            {category.items.map(item => (
                              <div key={item.id} className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <Checkbox 
                                    checked={item.checked}
                                    onCheckedChange={(checked) => 
                                      handleCheckObservation(key, item.id, checked)
                                    }
                                  />
                                  <span className="text-sm">{item.text}</span>
                                </div>
                                <Button variant="ghost" size="sm">
                                  <MessageSquare className="h-4 w-4" />
                                </Button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Notes and Recording */}
              <div className="space-y-4">
                {/* Recording Tile */}
                <Card>
                  <CardHeader className="py-3">
                    <div className="flex items-center justify-between">
                      <CardTitle className="text-sm flex items-center">
                        <Mic className="h-4 w-4 mr-2" />
                        Session Recording
                      </CardTitle>
                      {isSessionActive && (
                        <div className="flex items-center text-red-500">
                          <span className="animate-pulse mr-2">●</span>
                          Recording
                        </div>
                      )}
                    </div>
                  </CardHeader>
                  <CardContent className="space-y-3">
                    <div className="flex space-x-2">
                      <Button 
                        variant={isSessionActive ? "destructive" : "default"}
                        onClick={() => setIsSessionActive(!isSessionActive)}
                        className="flex-1"
                      >
                        {isSessionActive ? (
                          <><Pause className="mr-2 h-4 w-4" /> Stop Recording</>
                        ) : (
                          <><Mic className="mr-2 h-4 w-4" /> Start Recording</>
                        )}
                      </Button>
                      <Button 
                        variant="outline"
                        onClick={() => setShowDeviceInput(!showDeviceInput)}
                      >
                        <Smartphone className="h-4 w-4" />
                      </Button>
                    </div>

                    {/* Device Pairing Section */}
                    {showDeviceInput && (
                      <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                        <div className="flex items-center justify-between">
                          <span className="text-sm font-medium">Device Code</span>
                          <Button 
                            variant="ghost" 
                            size="sm"
                            onClick={generateCode}
                          >
                            <RefreshCw className="h-4 w-4 mr-1" />
                            Generate New
                          </Button>
                        </div>
                        {deviceCode ? (
                          <div className="flex items-center justify-between bg-white p-3 rounded border">
                            <span className="text-2xl font-mono font-bold tracking-wider">
                              {deviceCode}
                            </span>
                            <Button 
                              variant="ghost" 
                              size="sm"
                              onClick={() => navigator.clipboard.writeText(deviceCode)}
                            >
                              <Copy className="h-4 w-4" />
                            </Button>
                          </div>
                        ) : (
                          <Button 
                            className="w-full" 
                            onClick={generateCode}
                          >
                            Generate Code
                          </Button>
                        )}
                        <p className="text-xs text-gray-500">
                          Enter this code in the PsychAccess mobile app to start recording from your device
                        </p>
                      </div>
                    )}

                    {/* Live Transcription */}
                    {isSessionActive && (
                      <div className="bg-gray-50 rounded-lg p-3">
                        <div className="flex items-center justify-between mb-2">
                          <span className="text-sm font-medium">Live Transcription</span>
                          <span className="text-xs text-gray-500">
                            <Loader2 className="h-3 w-3 animate-spin inline-block mr-1" />
                            Transcribing...
                          </span>
                        </div>
                        <div className="h-[100px] bg-white rounded border p-2 overflow-y-auto">
                          {transcript.map((entry, index) => (
                            <div key={index} className="flex space-x-2">
                              <span className="text-xs text-gray-500">{entry.timestamp}</span>
                              <span className="text-xs font-medium">{entry.speaker}:</span>
                              <span className="text-sm flex-1">{entry.text}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>

                {/* Notes Tile */}
                <Card>
                  <CardHeader className="py-3">
                    <CardTitle className="text-sm flex items-center justify-between">
                      <div className="flex items-center">
                        <MessageSquare className="h-4 w-4 mr-2" />
                        Session Notes
                      </div>
                      <Button 
                        variant="ghost" 
                        size="sm"
                        onClick={() => setShowFullScreenNotes(true)}
                      >
                        <Maximize2 className="h-4 w-4" />
                      </Button>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Textarea 
                      className="min-h-[200px]" 
                      placeholder="Enter session notes..."
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>

          {/* Right Sidebar - Signals & Documents */}
          <div className="col-span-3 space-y-4">
            {/* AI Signals */}
            <Card>
              <CardHeader className="py-3">
                <CardTitle className="text-sm flex items-center">
                  <Brain className="h-4 w-4 mr-2" />
                  AI Signals
                </CardTitle>
              </CardHeader>
              <CardContent className="max-h-[300px] overflow-y-auto">
                {signals.map(signal => (
                  <div key={signal.id} className="mb-2 p-2 bg-gray-50 rounded">
                    <div className="flex items-center justify-between">
                      <span className="text-sm font-medium">{signal.type}</span>
                      <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
                        {(signal.confidence * 100).toFixed(0)}%
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 mt-1">{signal.description}</p>
                  </div>
                ))}
              </CardContent>
            </Card>

            {/* Documents Quick View */}
            <Card>
              <CardHeader className="py-3">
                <CardTitle className="text-sm flex items-center">
                  <FileText className="h-4 w-4 mr-2" />
                  Documents
                </CardTitle>
              </CardHeader>
              <CardContent>
                {documents.map(doc => (
                  <div 
                    key={doc.id}
                    className="flex items-center justify-between p-2 hover:bg-gray-50 rounded cursor-pointer"
                  >
                    <span className="text-sm">{doc.name}</span>
                    <div className="flex items-center space-x-1">
                      {doc.signals?.length > 0 && (
                        <AlertCircle className="h-4 w-4 text-red-500" />
                      )}
                      <Button 
                        variant="ghost" 
                        size="sm" 
                        onClick={() => handleExtractHighlights(doc.id)}
                      >
                        <FileText className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="sm">
                        <Eye className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>

            {/* Upload Data Box */}
            <Card>
              <CardHeader className="py-3">
                <CardTitle className="text-sm flex items-center justify-between">
                  <div className="flex items-center">
                    <Upload className="h-4 w-4 mr-2" />
                    Upload Data
                  </div>
                  <Button variant="ghost" size="sm">
                    <FolderOpen className="h-4 w-4" />
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="border-2 border-dashed rounded-lg p-4 text-center">
                  <Upload className="h-8 w-8 mx-auto mb-2 text-gray-400" />
                  <p className="text-sm text-gray-600">
                    Drag and drop files here, or click to select
                  </p>
                  <Button variant="outline" className="mt-2" size="sm">
                    Choose File
                  </Button>
                </div>
              </CardContent>
            </Card>

            {/* From The Documents tile */}
            <Card>
              <CardHeader className="py-3">
                <CardTitle className="text-sm flex items-center">
                  <FileText className="h-4 w-4 mr-2" />
                  From The Documents
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div>
                  <h3 className="text-sm font-medium mb-2">Current Interventions</h3>
                  <ul className="text-sm text-gray-600 space-y-1">
                    <li>• Individual Therapy (Started: Jan 2024)</li>
                    <li>• Medication Management</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-sm font-medium mb-2">Prior Diagnoses</h3>
                  <ul className="text-sm text-gray-600 space-y-1">
                    <li>• F90.2 ADHD Combined Type</li>
                    <li>• F41.1 Generalized Anxiety</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-sm font-medium mb-2">Intake Responses</h3>
                  <div className="space-y-2">
                    <div>
                      <p className="text-sm font-medium">Reason for Visit</p>
                      <p className="text-sm text-gray-600">
                        "Seeking help with attention difficulties and anxiety management"
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium">Goals for Treatment</p>
                      <p className="text-sm text-gray-600">
                        "Improve focus at school, develop better coping strategies"
                      </p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {showFullScreen && (
        <GlobalObservationsModal 
          isOpen={showFullScreen}
          onClose={() => setShowFullScreen(false)}
          observations={observationCategories}
          onUpdate={(categoryKey, itemId, updates) => {
            // Handle updates to observations
            console.log('Update:', categoryKey, itemId, updates);
            // You can implement the actual update logic here
          }}
        />
      )}

      {showCloseDialog && (
        <CloseSessionDialog 
          isOpen={showCloseDialog}
          onClose={() => setShowCloseDialog(false)}
        />
      )}

      {showPostSessionModal && (
        <PostSessionModal 
          isOpen={showPostSessionModal}
          onClose={() => setShowPostSessionModal(false)}
        />
      )}
    </div>
  );
};

export default SessionInterface;